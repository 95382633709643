import { FC, useRef, useEffect } from "react";
import { videoHeight, videoWidth } from "../constants";
import styled from "@emotion/styled";

type ZoomBoxProps = {
  scale: number;
  gestureZoom: number;
  showVideo: boolean;
};

const Wrapper = styled.div`
  position: absolute;
`;

const ZoomBox: FC<ZoomBoxProps> = ({ scale, gestureZoom, showVideo }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const w = (1 / gestureZoom) * videoWidth;
        const x = (videoWidth - w) / 2;
        const h = (1 / gestureZoom) * videoHeight;
        const y = (videoHeight - h) / 2;
        ctx.clearRect(0, 0, videoWidth, videoHeight);
        ctx.beginPath();
        ctx.rect(x, y, w, h);
        ctx.lineWidth = 4;
        ctx.strokeStyle="rgba(255,255,255,0.5)"
        ctx.stroke();
      }
    }
  }, [ gestureZoom]);

  return (
    <Wrapper>
      {showVideo ? (
        <canvas
          ref={canvasRef}
          width={videoWidth}
          height={videoHeight}
          style={{
            width: videoWidth * scale,
            height: videoHeight * scale,
          }}
        />
      ) : null}
    </Wrapper>
  );
};

export default ZoomBox;
