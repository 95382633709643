import { FC, useEffect, useRef, useMemo } from "react";
import { Positionable } from "./Layer";
import { Observable } from "rxjs";
import { InteractionMessage } from "../../grid/components/Grid";
import { v4 as uuidv4 } from "uuid";

type CursorProps = {
  x: number;
  y: number;
  visible: boolean;
  interactionStream$: Observable<InteractionMessage>;
  sideBlur?: boolean;
  blurFactor?: number;
};

const cursorAnimation = [
  { transform: "scale(1)" },
  { transform: "scale(0.8)", offset: 0.333 },
  { transform: "scale(1)" },
];

const animationTiming = {
  duration: 150,
  iterations: 1,
};

const svgSize = 300;
const halfSVGSize = svgSize / 2;
const cursorRadius = 30;

const Cursor: FC<CursorProps> = ({
  x,
  y,
  visible,
  interactionStream$,
  sideBlur,
  blurFactor,
}) => {
  const filterId = useMemo(() => `filter${uuidv4()}`, []);
  const animationRef = useRef<HTMLDivElement>(null);
  const windowDimensionsRef = useRef<{ w: number; h: number }>({
    w: 1000,
    h: 1000,
  });

  useEffect(() => {
    const subscription = interactionStream$.subscribe((next) => {
      if (next.kind === "click" && animationRef.current) {
        animationRef.current.animate(cursorAnimation, animationTiming);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [interactionStream$]);

  useEffect(() => {
    function getWindowDimensions() {
      windowDimensionsRef.current = {
        w: window.innerWidth,
        h: window.innerHeight,
      };
    }
    window.addEventListener("resize", getWindowDimensions);
    getWindowDimensions();
    return () => {
      window.removeEventListener("resize", getWindowDimensions);
    };
  }, []);

  if (!visible) return <Positionable x={0} y={0} />;

  let xx = x;
  let yy = y;
  let blurValue = 0;
  let blurOverride = blurFactor !== undefined ? blurFactor * 5 : 4;

  if (sideBlur) {
    if (xx < 0) {
      xx = 0;
      blurValue = blurOverride;
    }
    if (yy < 0) {
      yy = 0;
      blurValue = blurOverride;
    }
    if (xx > windowDimensionsRef.current.w) {
      xx = windowDimensionsRef.current.w;
      blurValue = blurOverride;
    }
    if (yy > windowDimensionsRef.current.h) {
      yy = windowDimensionsRef.current.h;
      blurValue = blurOverride;
    }
  }

  return (
    <Positionable x={xx - halfSVGSize} y={yy - halfSVGSize}>
      <div ref={animationRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={svgSize} //84
          height={svgSize}
        >
          <filter id={filterId}>
            <feGaussianBlur in="SourceGraphic" stdDeviation={`${blurValue}`} />
          </filter>
          <g filter={`url(#${filterId})`}>
            <circle
              id="b"
              r={cursorRadius}
              fill="#FFF"
              fillOpacity=".5"
              cx={halfSVGSize}
              cy={halfSVGSize}
            />
          </g>
        </svg>
      </div>
    </Positionable>
  );
};

export default Cursor;
