class SoundManager {
  private _audioBuffers = new Map<string, AudioBuffer>();
  private audioContext;
  private audioCtx;

  constructor() {
    this.audioContext = window.AudioContext; // || window.webkitAudioContext;
    this.audioCtx = new AudioContext();
  }

  async getFile(filepath: string): Promise<AudioBuffer> {
    const response = await fetch(filepath);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.audioCtx.decodeAudioData(arrayBuffer);
    return audioBuffer;
  }

  async loadFile(filePath: string): Promise<AudioBuffer> {
    const track = await this.getFile(filePath);
    return track;
  }

  playTrack(audioBuffer: AudioBuffer, offset = 0): AudioBufferSourceNode {
    const trackSource = this.audioCtx.createBufferSource();
    trackSource.buffer = audioBuffer;
    trackSource.connect(this.audioCtx.destination);
    if (offset === 0) {
      trackSource.start();
    } else {
      trackSource.start(0, this.audioCtx.currentTime - offset);
    }
    return trackSource;
  }

  public preload(sounds: { url: string; id: string }[]): void {
    // TODO Create a structure for playing sounds
    sounds.forEach((item) => {
      this.loadFile(item.url).then((track) => {
        this._audioBuffers.set(item.id, track);
      });
    });
  }

  playTrackWithId(id: string): void {
    const audioBuffer = this._audioBuffers.get(id);
    if (audioBuffer) this.playTrack(audioBuffer);
  }

  public playSound(id: string): void {
    this.playTrackWithId(id);
  }
}

export default SoundManager;
