import styled from "@emotion/styled";
import { FC, useEffect, useRef } from "react";
import BlazeHandsLogic from "../services/BlazeHandsLogic";
import { Observable, Subject } from "rxjs";
import { Results as HandResults } from "@mediapipe/hands";
import { Results as HolisticResults } from "@mediapipe/holistic";
import HolisticCaptureLogic from "../services/HolisticCaptureLogic";
import {PoseData} from "../utils/types";

const Container = styled.div``;

type BlazeHandsProps = {
  modelName: "hands" | "holistic";
  dataStreamCallback?: (observable: Observable<PoseData>) => void;
  showVideo?: boolean;
};

const PoseView: FC<BlazeHandsProps> = ({
  dataStreamCallback,
  modelName,
  showVideo,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const modelNameRef = useRef<"hands" | "holistic">(modelName);
  const poseEstimationRef = useRef<
    BlazeHandsLogic | HolisticCaptureLogic | null
  >(null);
  const dataStreamRef = useRef<Subject<PoseData>>(new Subject<PoseData>());

  useEffect(() => {
    function handleHandResults(results: HandResults) {
      // console.log("RESULTS >>> ", results);
      dataStreamRef.current.next({ results: results, model: "hands" });
    }

    function handleHolisticResults(results: HolisticResults) {
      dataStreamRef.current.next({ results: results, model: "holistic" });
    }

    if (
      videoRef.current &&
      canvasRef.current &&
      poseEstimationRef.current === null
    ) {
      if (modelName === "hands") {
        modelNameRef.current = "hands";
        // @ts-ignore
        poseEstimationRef.current = new BlazeHandsLogic(
          videoRef.current,
          canvasRef.current,
          handleHandResults
        );
      } else {
        modelNameRef.current = "holistic";
        // @ts-ignore
        poseEstimationRef.current = new HolisticCaptureLogic(
          videoRef.current,
          canvasRef.current,
          handleHolisticResults
        );
      }
    }
    return () => {
      // todo destroy BlazeHandsLogic
    };
  }, [modelName]);

  useEffect(() => {
    if (dataStreamCallback) {
      dataStreamCallback(dataStreamRef.current as Observable<PoseData>);
    }
  }, [dataStreamCallback]);

  return (
    <Container>
      <video ref={videoRef} style={{ display: "none" }} />
      <canvas
        width="1280px"
        height="720px"
        ref={canvasRef}
        style={{ display: showVideo === false ? "none" : "block" }}
      />
    </Container>
  );
};

export default PoseView;
