import { Point3D } from "./types";

export function subtractPoints(
  ptA: Point3D | null | undefined,
  ptB: Point3D | null | undefined
): Point3D | null {
  if (!ptA || !ptB) return null;
  const x = ptA.x - ptB.x;
  const y = ptA.y - ptB.y;
  const z = ptA.z - ptB.z;
  return { x, y, z };
}

export function dividePoint(pt: Point3D, value: number) {
  const x = pt.x / value;
  const y = pt.x / value;
  const z = pt.x / value;
  return { x, y, z };
}

export function multiplyPoint(pt: Point3D, value: number) {
  const x = pt.x * value;
  const y = pt.x * value;
  const z = pt.x * value;
  return { x, y, z };
}
