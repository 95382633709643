import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

export type ControllableIconProps = {
  imageSrc: string;
  position: { x: string; y: string };
  active: boolean;
  scale: number;
  opacity: number;
};

type ImageDivProps = {
  imageSrc: string;
  x: string;
  y: string;
  width: number;
  height: number;
  opacity: number;
};

const ImageDiv = styled.div<ImageDivProps>`
  background-image: url(${(props) => props.imageSrc});
  background-size: contain;
  top: ${(props) => props.y};
  left: ${(props) => props.x};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: absolute;
  transition: opacity 2s;
  opacity: ${(props) => props.opacity};
`;

const ControllableIcon: React.FC<ControllableIconProps> = ({
  imageSrc,
  position,
  active,
  scale,
  opacity,
}) => {
  const [imageDimensions, setImageDimensions] = useState<{
    w: number;
    h: number;
  }>({ w: 50, h: 50 });
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const img = new Image();
    img.addEventListener("load", () => {
      setImageDimensions({ w: img.width, h: img.height });
    });
    img.src = imageSrc;
  }, [imageSrc]);

  return (
    <ImageDiv
      ref={divRef}
      imageSrc={imageSrc}
      width={imageDimensions.w * scale}
      height={imageDimensions.h * scale}
      x={position.x}
      y={position.y}
      opacity={active ? opacity : 0}
    />
  );
};

export default ControllableIcon;
