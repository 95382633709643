import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { HAND_CONNECTIONS, Hands, Results } from "@mediapipe/hands";
import { Camera } from "@mediapipe/camera_utils";

class BlazeHandsLogic {
  videoElement: HTMLVideoElement;
  canvasElement: HTMLCanvasElement;
  canvasCtx: CanvasRenderingContext2D | null;
  hands: Hands;
  camera: Camera;
  callback: null | ((results: Results) => void);

  constructor(
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    callback: null | ((results: Results) => void) = null
  ) {
    // Init variables
    this.videoElement = video;
    this.canvasElement = canvas;
    this.canvasCtx = canvas.getContext("2d");
    this.callback = callback;

    // Version of hands  "@mediapipe/hands": "^0.3.1630010197",

    // Init Hand tracking
    this.hands = new Hands({
      locateFile: (file) => {
        //return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.3.1634603088/${file}`;
        return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.3.1632795355/${file}`;
      },
    });

    this.hands.setOptions({
      maxNumHands: 2,
      minDetectionConfidence: 0.8,
      minTrackingConfidence: 0.8,
    });
    this.hands.onResults(this.onResults.bind(this));

    // Init Camera
    this.camera = new Camera(this.videoElement, {
      onFrame: async () => {
        await this.hands.send({ image: this.videoElement });
      },
      width: 1280,
      height: 720,
    });

    this.camera.start();
  }

  onResults(results: Results): void {
    if (this.callback) {
      this.callback(results);
    }

    if (!this.canvasCtx) return;
    const canvasCtx = this.canvasCtx;

    canvasCtx.save();
    canvasCtx.clearRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    if (results.multiHandLandmarks) {
      for (const landmarks of results.multiHandLandmarks) {
        drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS, {
          color: "#00FF00",
          lineWidth: 5,
        });
        drawLandmarks(canvasCtx, landmarks, { color: "#FF0000", lineWidth: 2 });
      }
    }
    canvasCtx.restore();
  }

  destroy() {
    // TODO
  }
}

export default BlazeHandsLogic;
