import styled from "@emotion/styled";

const VideoHolder = styled.div`
  transform: scale(0.25) translate(-150%, -150%);
  position: absolute;
  top: 0px;
  left: 0;
`;

export default VideoHolder;
