import {
  FACEMESH_TESSELATION,
  HAND_CONNECTIONS,
  Holistic,
  POSE_CONNECTIONS,
  Results,
} from "@mediapipe/holistic";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { Camera } from "@mediapipe/camera_utils";

class HolisticCaptureLogic {
  videoElement: HTMLVideoElement;
  canvasElement: HTMLCanvasElement;
  canvasCtx: CanvasRenderingContext2D | null;
  holistic: Holistic | null = null;
  camera: Camera | null = null;
  callback: ((results: Results) => void) | undefined = undefined;

  constructor(
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    callback?: (results: Results) => void
  ) {
    this.videoElement = video;
    this.canvasElement = canvas;
    this.canvasCtx = this.canvasElement.getContext("2d");
    this.callback = callback;
    this.init();
  }

  onResults(results: Results) {
    if (this.callback) {
      this.callback(results);
    }
    if (!this.canvasCtx) return;
    this.canvasCtx.save();
    this.canvasCtx.clearRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    this.canvasCtx.drawImage(
      results.image,
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    drawConnectors(this.canvasCtx, results.poseLandmarks, POSE_CONNECTIONS, {
      color: "#00FF00",
      lineWidth: 4,
    });
    drawLandmarks(this.canvasCtx, results.poseLandmarks, {
      color: "#FF0000",
      lineWidth: 2,
    });
    drawConnectors(
      this.canvasCtx,
      results.faceLandmarks,
      FACEMESH_TESSELATION,
      {
        color: "#C0C0C070",
        lineWidth: 1,
      }
    );
    drawConnectors(
      this.canvasCtx,
      results.leftHandLandmarks,
      HAND_CONNECTIONS,
      {
        color: "#CC0000",
        lineWidth: 5,
      }
    );
    drawLandmarks(this.canvasCtx, results.leftHandLandmarks, {
      color: "#00FF00",
      lineWidth: 2,
    });
    drawConnectors(
      this.canvasCtx,
      results.rightHandLandmarks,
      HAND_CONNECTIONS,
      {
        color: "#00CC00",
        lineWidth: 5,
      }
    );
    drawLandmarks(this.canvasCtx, results.rightHandLandmarks, {
      color: "#FF0000",
      lineWidth: 2,
    });
    this.canvasCtx.restore();
  }

  init() {
    this.holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
      },
    });
    this.holistic.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });
    this.holistic.onResults(this.onResults.bind(this));

    this.camera = new Camera(this.videoElement, {
      onFrame: async () => {
        if (this.holistic)
          await this.holistic.send({ image: this.videoElement });
      },
      width: 1280,
      height: 720,
    });
    this.camera.start();
  }
}

export default HolisticCaptureLogic;
