import React, { FC, useEffect } from "react";
import "react-dat-gui/dist/index.css";
import DatGui, {
  DatBoolean,
  DatButton,
  DatColor,
  DatFolder,
  DatNumber,
  DatSelect,
} from "react-dat-gui";
import { InteractionMode, Settings } from "../types";
import { Stores } from "../stores";
import { useStores, useRxState } from "@ixd-group/react-utils";

type SettingsGuiProps = {
  settingsCallback?: (settings: Settings) => void;
};

const interactionModes: InteractionMode[] = ["Standard", "Proximity", "Focus"];

const SettingsGui: FC<SettingsGuiProps> = ({ settingsCallback }) => {
  const store = useStores<Stores>();
  const settings = useRxState(store.atoms.settings$);
  const setSettings = store.actions.setSettings;

  useEffect(() => {
    document.body.style.backgroundColor = settings.backgroundColour;
  }, [settings.backgroundColour]);

  function handleUpdate(updates: any) {
    const updatedInteractionMode: InteractionMode = updates.interactionMode;
    if (updatedInteractionMode !== settings.interactionMode) {
      updates.hideCursor =
        updatedInteractionMode === "Standard" ||
        updatedInteractionMode === "Proximity" ||
        updatedInteractionMode === "Focus";
    }
    const newSettings: Settings = { ...settings, ...updates };
    if (settingsCallback) {
      settingsCallback(newSettings);
    }
    setSettings(newSettings);
  }

  function handleResetSelection() {
    store.actions.clearSelections();
  }

  return (
    <DatGui data={settings} onUpdate={handleUpdate}>
      <DatSelect
        path={"interactionMode"}
        label="Interaction Mode"
        options={interactionModes}
      />
      <DatButton onClick={handleResetSelection} label={"Clear selections"} />
      <DatFolder title={"Settings"} closed={true}>
        <DatNumber path="cols" label="columns" min={1} max={6} step={1} />
        <DatNumber path="rows" label="rows" min={1} max={5} step={1} />
        <DatNumber path="spacing" label="spacing" min={0} max={2} step={0.05} />
        <DatColor path={"backgroundColour"} label={"background"} />
        <DatBoolean path={"showVideo"} label={"show video"} />
        <DatBoolean path={"showFPS"} label={"show fps"} />
        <DatSelect
          options={["right", "left"]}
          path={"handedness"}
          label={"hand"}
        />
        <DatSelect
          options={["index finger", "wrist"]}
          path={"pointerTarget"}
          label={"pointer target"}
        />
        <DatNumber
          path={"gestureZoom"}
          label={"Gesture Zoom"}
          min={0.5}
          max={3}
          step={0.1}
        />
        <DatSelect
          options={["sounds 01", "sounds 02"]}
          path={"soundOptions"}
          label={"audio"}
        />
        <DatBoolean path={"sideBlur"} label={"side blur"} />
        <DatNumber
          path={"cursorDamping"}
          label={"cursor damping"}
          min={1}
          max={30}
          step={1}
        />
      </DatFolder>
    </DatGui>
  );
};

export default SettingsGui;
