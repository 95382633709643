import React, { useEffect, useRef } from "react";
import PoseView from "./PoseView";
import VideoHolder from "../utils/VideoHolder";
import Dynamics, {
  CursorsDataWithBlur,
} from "../services/Dynamics";
import { PointerTargetChoices, PoseData } from "../utils/types";
import { Observable, Subscription } from "rxjs";

type MLVisionProps = {
  dynamicsCallback?: (d: Dynamics) => void;
  cursorsCallback?: (cursors: CursorsDataWithBlur) => void;
  clickCallback?: (message: string) => void;
  showCursors?: boolean;
  showVideo?: boolean;
  showFPS?: boolean;
  pointerTarget: PointerTargetChoices;
  gestureZoom: number;
  cursorDamping: number;
};

const MLVision: React.FC<MLVisionProps> = ({
  dynamicsCallback,
  cursorsCallback,
  clickCallback,
  pointerTarget,
  showVideo,
  gestureZoom,
  cursorDamping,
}) => {
  const dynamicsRef = useRef(Dynamics.getInstance());
  dynamicsRef.current.setGestureZoom(gestureZoom);

  const dynamicSubscriptionToCursorMovementRef = useRef<Subscription>(null);
  const cursorRef = useRef<CursorsDataWithBlur>({
    left: null,
    right: null,
  });

  useEffect(() => {
    dynamicsRef.current.setCursorDamping(cursorDamping);
  }, [cursorDamping]);

  useEffect(() => {
    dynamicsRef.current.setPointerTarget(pointerTarget);
  }, [pointerTarget]);

  useEffect(() => {
    dynamicsRef.current.setGestureZoom(gestureZoom);
  }, [gestureZoom]);

  useEffect(() => {
    if (dynamicsCallback) {
      dynamicsCallback(dynamicsRef.current);
    }
  }, [dynamicsCallback]);

  useEffect(() => {
    let subscriptionA = dynamicsRef.current.cursors.subscribe((cursorsData) => {
      cursorRef.current = cursorsData;
    });
    let subscriptionB = dynamicsRef.current.clickStream.subscribe(() => {
      if (clickCallback) clickCallback("click");
    });
    function updateSize() {
      const w = window.innerWidth;
      const h = window.innerHeight;
      dynamicsRef.current.setScreenSize(w, h, 1);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
      subscriptionA.unsubscribe();
      subscriptionB.unsubscribe();
      if (dynamicSubscriptionToCursorMovementRef.current) {
        dynamicSubscriptionToCursorMovementRef.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    function animationLoop() {
      if (cursorsCallback) cursorsCallback(cursorRef.current);
      window.requestAnimationFrame(animationLoop);
    }
    animationLoop();
    // eslint-disable-next-line
  }, []);

  function handleSubscribeToCursorMovement(observable: Observable<PoseData>) {
    if (dynamicSubscriptionToCursorMovementRef.current === null) {
      console.log("Subscribing");
      // @ts-ignore
      dynamicSubscriptionToCursorMovementRef.current =
        dynamicsRef.current.subscribeToPoseData(observable);
    }
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <VideoHolder>
        <PoseView
          modelName={"hands"}
          dataStreamCallback={handleSubscribeToCursorMovement}
          showVideo={showVideo !== false}
        />
      </VideoHolder>
      {/* {showCursors ? [cLeft, cRight] : null} */}
    </div>
  );
};

export default MLVision;
