import { PoseData } from "../utils/types";
import { NormalizedLandmark, Results as HandResults } from "@mediapipe/hands";
import {
  BehaviorSubject,
  interval,
  Observable,
  scan,
  Subject,
  Subscription,
} from "rxjs";

import {
  NormalizedLandmarkList,
  Results as HolisticResults,
} from "@mediapipe/holistic";
import {
  map,
  filter,
  bufferCount,
  distinctUntilChanged,
  throttle,
} from "rxjs/operators";
import { multiplyPoint, subtractPoints } from "../utils/pointUtils";
import { Point3D, PointerTargetChoices } from "../utils/types";

import NeuralNet from "./NeuralNet";

const WRIST_INDEX = 0;
const INDEX_FINGER_TIP = 8;

export type TimedLandmark = {
  landmark: NormalizedLandmark | undefined;
  t: number;
  score?: number;
};

export type WristsData = {
  left: TimedLandmark | null;
  right: TimedLandmark | null;
};

export type HandData = {
  landmarks: NormalizedLandmarkList;
  score?: number;
  label: "Right" | "Left";
};

export type HandsData = {
  left: HandData | null;
  right: HandData | null;
  t: number;
};

export type CursorsData = {
  left: { x: number; y: number } | null;
  right: { x: number; y: number } | null;
};

export type CursorsDataWithBlur = {
  left: { x: number; y: number; blur: number } | null;
  right: { x: number; y: number; blur: number } | null;
};

export type TimedPoint3D = {
  x: number;
  y: number;
  z: number;
  t: number;
};

function timedLandmarkToPoint(tl: TimedLandmark): Point3D | null {
  if (tl.landmark) {
    return {
      x: tl.landmark.x,
      y: tl.landmark.y,
      z: tl.landmark.z,
    };
  } else return null;
}

class Dynamics {
  // Settings
  private landmarkIndex = 0;
  private static swipeThreshold = 0.64;
  private outputWidth: number = 800;
  private outputHeight: number = 600;
  private gestureZoom: number = 1;
  private nnLoaded = false;
  private nnBusy = false;
  private static instance: null | Dynamics = null;
  private cursorDamping = 4;

  // Neural Net
  private nn: NeuralNet = new NeuralNet();

  // Streams
  private handSubject: Subject<HandResults> = new Subject<HandResults>();
  private holisticSubject: Subject<HolisticResults> =
    new Subject<HolisticResults>();
  public handsObservable: Observable<HandsData> = this.handSubject.pipe(
    map(Dynamics.handleHandData2)
  );
  public wristsVelocity: Observable<TimedPoint3D | null> =
    Dynamics.calcWristVelocities(this.handsObservable);
  public swipes: Observable<string> = Dynamics.calcSwipes(this.wristsVelocity);
  public cursors: Observable<CursorsDataWithBlur> = this.handsObservable.pipe(
    map(this.calcCursors.bind(this)),
    scan((acc: CursorsDataWithBlur[], cur) => {
      acc.push(cur);
      return acc.slice(-this.cursorDamping);
    }, []),
    map(Dynamics.averageCursorPositions)
  );

  setCursorDamping(value: number) {
    this.cursorDamping = value;
  }

  public static averageCursorPositions(
    values: CursorsDataWithBlur[]
  ): CursorsDataWithBlur {
    const totals = {
      left: { x: 0, y: 0, blur: 0 },
      right: { x: 0, y: 0, blur: 0 },
    };
    let leftCount = 0;
    let rightCount = 0;
    values.forEach((v) => {
      if (v.left !== null) {
        totals.left.x += v.left.x;
        totals.left.y += v.left.y;
        totals.left.blur += v.left.blur;
        leftCount += 1;
      }
      if (v.right !== null) {
        totals.right.x += v.right.x;
        totals.right.y += v.right.y;
        totals.right.blur += v.right.blur;
        rightCount += 1;
      }
    });

    const result: CursorsDataWithBlur = { left: null, right: null };

    if (rightCount > 0) {
      result.right = {
        x: totals.right.x / rightCount,
        y: totals.right.y / rightCount,
        blur: totals.right.blur / rightCount,
      };
    }
    if (leftCount > 0) {
      result.left = {
        x: totals.left.x / leftCount,
        y: totals.left.y / leftCount,
        blur: totals.left.blur / leftCount,
      };
    }
    return result;
  }

  // TODO create a better type for this
  private handGesturesSubject: BehaviorSubject<string> = new BehaviorSubject(
    "nothing"
  );
  public handGestureStream: Observable<string> = this.handGesturesSubject.pipe(
    distinctUntilChanged()
  );
  public clickStream: Observable<string> = this.handGestureStream.pipe(
    map((v) => {
      if (v === "closed" || v === "fist") {
        return "click";
      } else {
        return "not click";
      }
    }),
    filter((v) => v === "click")
  );

  public static getInstance(): Dynamics {
    if (Dynamics.instance === null) {
      Dynamics.instance = new Dynamics();
    }
    return Dynamics.instance;
  }

  constructor() {
    this.initNeuralNet();
  }

  initNeuralNet() {
    this.nn.load(this.handleNetLoaded.bind(this));
  }

  handleNetLoaded() {
    console.log("Neural Net Ready");
    this.nnLoaded = true;
    this.handSubject
      .pipe(throttle((val) => interval(30)))
      .subscribe((handResults: HandResults) => {
        this.processResultsWithNet(handResults);
      });
  }

  processResultsWithNet(handResults: HandResults) {
    if (this.nnLoaded && !this.nnBusy) {
      this.nn.classify(
        { results: handResults },
        (error: any, netResult: any) => {
          this.nnBusy = false;
          // console.log("Error:", error, " result:", netResult);
          if (!error) {
            const { label, confidence } = netResult[0];
            let gesture = label;
            if (confidence < 0.9) {
              gesture = "nothing";
              // console.log(label, "-> nothing", confidence);
            }
            this.handGesturesSubject.next(gesture);
          }
        }
      );
    } else {
      console.log("Waiting for neural net to load");
    }
  }

  public setScreenSize(width: number, height: number, scale: number) {
    this.outputWidth = width;
    this.outputHeight = height;
    this.gestureZoom = scale;
  }

  public setGestureZoom(value: number) {
    this.gestureZoom = value;
  }

  setPointerTarget(pointerTarget: PointerTargetChoices) {
    switch (pointerTarget) {
      case "index finger":
        this.landmarkIndex = INDEX_FINGER_TIP;
        break;
      case "wrist":
        this.landmarkIndex = WRIST_INDEX;
        break;
    }
  }

  calcBlurFactor(p: number, gestureZoom: number) {
    const centerSize = 1 / gestureZoom;
    const margin = (1 - centerSize) / 2;
    if (p >= margin && p <= centerSize + margin) {
      return 0;
    }
    if (p < margin) {
      return -(p - margin) / margin;
    }
    return (p - margin - centerSize) / margin;
  }

  handToCursor(
    hand: HandData | null,
    index: number
  ): { x: number; y: number; blur: number } | null {
    if (!hand) return null;
    if (!hand.landmarks[index]) return null;
    const landmark: NormalizedLandmark = hand.landmarks[index];
    const x =
      this.outputWidth / 2 -
      this.gestureZoom * ((landmark.x - 0.5) * this.outputWidth);
    const y =
      this.outputHeight / 2 +
      this.gestureZoom * ((landmark.y - 0.5) * this.outputHeight);

    //console.log(x, hand.landmarks[0].x, this.outputWidth);
    const blurX = this.calcBlurFactor(landmark.x, this.gestureZoom);
    const blurY = this.calcBlurFactor(landmark.y, this.gestureZoom);
    const blur = Math.max(blurX, blurY);
    return { x, y, blur };
  }

  private calcCursors(hands: HandsData): CursorsDataWithBlur {
    // console.log(this)

    return {
      left: this.handToCursor(hands.left, this.landmarkIndex),
      right: this.handToCursor(hands.right, this.landmarkIndex),
    };
  }

  // Obtains the incoming data from PoseView
  public subscribeToPoseData(observable: Observable<PoseData>): Subscription {
    return observable.subscribe((data) => {
      switch (data.model) {
        case "hands":
          this.handSubject.next(data.results);
          break;
        case "holistic":
          this.holisticSubject.next(data.results);
          break;
      }
    });
  }

  private static calcSwipes(
    observable: Observable<TimedPoint3D | null>
  ): Observable<"swingLeft" | "swingRight" | "none"> {
    const samples = 8;
    const minSwipes = 5;
    return observable.pipe(
      bufferCount(samples, 1),

      map((vels: (TimedPoint3D | null)[]) => {
        let swipeRight = 0;
        let swipeLeft = 0;
        vels.forEach((v: TimedPoint3D | null) => {
          if (v) {
            if (v.x * 1000 > Dynamics.swipeThreshold) {
              swipeRight += 1;
            } else if (v.x * 1000 < -Dynamics.swipeThreshold) {
              swipeLeft += 1;
            }
          }
        });
        if (swipeRight >= minSwipes) {
          return "swingRight";
        }
        if (swipeLeft >= minSwipes) {
          return "swingLeft";
        }
        return "none";
      })
    );
  }

  static calcWristVelocities(
    obsHandsData: Observable<HandsData>
  ): Observable<TimedPoint3D | null> {
    const rightWristObs: Observable<TimedLandmark> = obsHandsData.pipe(
      map((hands) => {
        const label = "left";
        return {
          t: hands.t,
          score: hands[label]?.score,
          landmark: hands[label]?.landmarks[WRIST_INDEX],
        };
      })
    );
    return Dynamics.landmarkVelocity(rightWristObs);
  }

  static landmarkVelocity(
    observable: Observable<TimedLandmark>
  ): Observable<TimedPoint3D | null> {
    return observable.pipe(
      bufferCount(20, 1),
      map((values: TimedLandmark[]) => {
        const l = values.length;
        const v0: TimedLandmark = values[0];
        const vn: TimedLandmark = values[l - 1];
        const pt1: Point3D | null = timedLandmarkToPoint(v0);
        const pt2: Point3D | null = timedLandmarkToPoint(vn);
        const t1 = values[0].t;
        const t2 = values[l - 1].t;
        const vel = Dynamics.calculateVelocity(t1, t2, pt1, pt2);
        if (vel) return { ...vel, t: t2 };
        else return null;
      })
    );
  }

  static calculateVelocity(
    t1: number,
    t2: number,
    pt1: Point3D | null,
    pt2: Point3D | null
  ): Point3D | null {
    if (!pt1 || !pt2) return null;
    const diff = subtractPoints(pt1, pt2);
    const dt = t2 - t1;
    if (diff !== null && dt !== 0) {
      return multiplyPoint(diff, 1 / dt);
    } else return null;
  }

  // Splits data from Blazepose hands in left and right hands
  static handleHandData2(results: HandResults): HandsData {
    const t = new Date().getTime();
    let left: HandData | null = null;
    let right: HandData | null = null;
    if (
      results.multiHandLandmarks &&
      results.multiHandedness &&
      results.multiHandedness.length > 0 &&
      results.multiHandLandmarks.length > 0
    ) {
      let endFound = false;
      let counter = 0;
      while (!endFound) {
        const handedness = results.multiHandedness[counter];
        const label = handedness.label;
        const landmarks: NormalizedLandmarkList =
          results.multiHandLandmarks[counter];
        if (label === "Left") {
          left = {
            landmarks,
            score: handedness.score,
            label,
          };
        }
        if (label === "Right") {
          right = {
            landmarks,
            score: handedness.score,
            label,
          };
        }
        counter += 1;
        if (left && right) endFound = true;
        if (counter >= results.multiHandedness.length) endFound = true;
      }
    }
    return { t, left, right };
  }
}

export default Dynamics;
