import { atom } from "@ixd-group/rx-utils";
import { Observable, Subject } from "rxjs";

import { Command, Settings } from "./types";
import { CursorInformation } from "../grid/types";
import SoundManager from "./utils/SoundManager";

export const defaultSettings: Settings = {
  size: 100,
  cols: 4,
  rows: 2,
  gestureZoom: 1.8,
  backgroundColour: "#282828",
  cursorSize: 20,
  hideCursor: false,
  interactionMode: "Standard",
  spacing: 0.15,
  handedness: "right",
  showVideo: true,
  pointerTarget: "wrist",
  showFPS: true,
  soundOptions: "sounds 03",
  sideBlur: true,
  cursorDamping: 3,
};

export const createStores = () => {
  const settings$ = atom<Settings>(defaultSettings);
  const commands$ = new Subject<Command>();
  const cursor$ = atom<CursorInformation>({ x: 0, y: 0, visible: false, blur:0 });
  const handAction$ = new Subject<string>();
  const soundManager = new SoundManager();
  soundManager.preload([
    {
      url: "assets/sounds/version01/tile-hover.mp3",
      id: "hover1",
    },
    {
      url: "assets/sounds/version01/tile-off.mp3",
      id: "off1",
    },
    {
      url: "assets/sounds/version01/tile-on.mp3",
      id: "on1",
    },

    {
      url: "assets/sounds/version02/tile-hover.mp3",
      id: "hover2",
    },
    {
      url: "assets/sounds/version02/tile-off.mp3",
      id: "off2",
    },
    {
      url: "assets/sounds/version02/tile-on.mp3",
      id: "on2",
    },

    {
      url: "assets/sounds/version03/tile-hover.mp3",
      id: "hover3",
    },
    {
      url: "assets/sounds/version03/tile-off.mp3",
      id: "off3",
    },
    {
      url: "assets/sounds/version03/tile-on.mp3",
      id: "on3",
    },
  ]);

  function setSettings(settings: Settings) {
    settings$.set(settings);
  }

  function clearSelections() {
    commands$.next({ command: "clearSelection" });
  }

  function updateCursor(data: CursorInformation) {
    cursor$.set(data);
  }

  function updateGesture(data: string) {
    //console.log(data);
  }

  function playSound(id: string) {
    soundManager.playSound(id);
  }

  return {
    atoms: {
      settings$: settings$.readonly(),
      cursor$: cursor$.readonly(),
    },
    streams: {
      commands$: commands$ as Observable<Command>,
      handAction$,
    },
    actions: {
      setSettings,
      clearSelections,
      updateCursor,
      updateGesture,
      playSound,
    },
  };
};

export type Stores = ReturnType<typeof createStores>;
