import VideoHolder from "../blazepose/components/VideoHolder";
import Grid from "../grid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { InteractionMessage } from "../grid/components/Grid";
import { Observable, Subject, Subscription } from "rxjs";
import SettingsMenu from "./components/SettingsMenu";
import { createStores } from "./stores";
import { createServices } from "./services";
import { ModuleContext, useRxState } from "@ixd-group/react-utils";
import Cursor from "./components/Cursor";
import { CursorsDataWithBlur } from "../blazepose/services/Dynamics";
import { CursorInformation } from "../grid/types";
import { BoxPackage } from "../grid/components/SelectableBox";
import ControllableIcon from "./components/ControllableIcon";
import ZoomBox from "./components/ZoomBox";
import { FPSStats } from "fps-react";

const Container = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const interactionStreamRef = useRef<Subject<InteractionMessage>>(
    new Subject()
  );
  const boxStreamSubscriptionRef = useRef<Subscription>(null);
  const stores = useMemo(createStores, []);
  const services = useMemo(createServices, []);
  const [settings] = useRxState([stores.atoms.settings$]);
  const [cursorState, setCursorState] = useState<CursorInformation>({
    x: 0,
    y: 0,
    visible: false,
    blur: 0,
  });

  useEffect(() => {
    // Listen out for window size changes
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Listen for clear selection
    const subscription = stores.streams.commands$.subscribe((next) => {
      switch (next.command) {
        case "clearSelection":
          const interactionStream$ = interactionStreamRef.current;
          interactionStream$.next({ kind: "unselect all" });
          break;
      }
    });

    // Remove handlers
    return () => {
      window.removeEventListener("resize", handleResize);
      subscription.unsubscribe();
    };
  }, [stores.streams.commands$]);

  function handleBoxStream(boxStream: Observable<BoxPackage>) {
    if (boxStreamSubscriptionRef.current !== null) {
      boxStreamSubscriptionRef.current.unsubscribe();
    }

    // @ts-ignore
    boxStreamSubscriptionRef.current = boxStream.subscribe(
      (boxPackage: BoxPackage) => {
        let soundSuffix = "";
        switch (settings.soundOptions) {
          case "sounds 01":
            soundSuffix = "1";
            break;
          case "sounds 02":
            soundSuffix = "2";
            break;
          case "sounds 03":
            soundSuffix = "3";
            break;
        }
        switch (boxPackage.message) {
          case "over":
            stores.actions.playSound(`hover${soundSuffix}`);
            break;
          case "select":
            stores.actions.playSound(`on${soundSuffix}`);
            break;
          case "unselect":
            stores.actions.playSound(`off${soundSuffix}`);
            break;
        }
      }
    );
  }

  function handleCursors(cursors: CursorsDataWithBlur): void {
    let cursorPt = { x: 0, y: 0, visible: false, blur: 0 };
    let cursor = null;
    if (cursors.left && cursors.right) {
      if (settings.handedness === "left") {
        cursor = cursors.left;
      } else {
        cursor = cursors.right;
      }
    } else {
      if (cursors.left || cursors.right) {
        if (cursors.left) {
          cursor = cursors.left;
        } else {
          cursor = cursors.right;
        }
      }
    }
    if (cursor) {
      console.log(cursor.blur);
      cursorPt = { x: cursor.x, y: cursor.y, visible: true, blur: cursor.blur };
    }
    setCursorState(cursorPt);
  }

  function handleClick(message: string): void {
    stores.actions.playSound("hover");
    interactionStreamRef.current.next({ kind: "click" });
  }

  return (
    <ModuleContext.Provider value={{ stores, services }}>
      <div data-testid="mainContainer" style={{ overflow: "hidden" }}>
        <VideoHolder
          cursorsCallback={handleCursors}
          pointerTarget={settings.pointerTarget}
          showVideo={settings.showVideo}
          showFPS={settings.showFPS}
          gestureZoom={settings.gestureZoom}
          clickCallback={handleClick}
          cursorDamping={settings.cursorDamping}
        />
        <ZoomBox
          scale={0.25}
          gestureZoom={settings.gestureZoom}
          showVideo={settings.showVideo}
        />
        <Grid
          width={windowDimensions.width}
          height={windowDimensions.height}
          borderRatio={settings.spacing}
          columns={settings.cols}
          rows={settings.rows}
          cursor={cursorState}
          interactionMode={settings.interactionMode}
          interactionStream$={interactionStreamRef.current}
          boxStreamCallback={handleBoxStream}
        />
        <Cursor
          x={cursorState.x}
          y={cursorState.y}
          visible={cursorState.visible}
          interactionStream$={interactionStreamRef.current}
          sideBlur={settings.sideBlur}
          blurFactor={cursorState.blur}
        />
        <ControllableIcon
          imageSrc={"assets/icons/gesture-active-white.png"}
          position={{ x: "calc(50% - 44px)", y: "40px" }}
          active={cursorState.visible}
          scale={0.5}
          opacity={0.5}
        />
        <SettingsMenu />
        {settings.showFPS ? <FPSStats bottom={0} top={"auto"} /> : null}
      </div>
    </ModuleContext.Provider>
  );
};

export default Container;
